import React from 'react';
import styled from 'styled-components';
import BackgroundImg from '../../shared/img/map_bg.jpg';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import { Dispatch } from 'redux';
import { toggleSplashScreenVisibility } from '../../store/actions/splashScreen';
import Spinner from '../Spinner/Spinner';
import { Image } from '../Image';

const LoadingMessageWrapper = styled.div`
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: ${1 << 12};
`;

const BackgroundImage = styled(Image)`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
`;

const Message = styled.span`
    display: block;
`;

const SplashContent = styled.div`
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    color: ${(props) => props.theme.baseFontColor};
    font-weight: bold;
    font-size: large;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
`;

const Link = styled.button`
    appearance: none;
    cursor: pointer;
    background: none;
    text-decoration: underline;
    color: ${(props) => props.theme.ingLinkColor};
    border: none;
    .notouch & {
        &:hover {
            color: ${(props) => props.theme.ingIndigo};
            background-color: ${(props) => props.theme.ingLinkHoverBgColor};
        }
    }
`;

type LoadingMessageProps = {
    splashScreenMessage?: string;
    toggleSplashScreenVisibility: (show: boolean) => void;
};

const LoadingMessage = ({
    splashScreenMessage,
    toggleSplashScreenVisibility,
}: LoadingMessageProps) => {
    const { t } = useTranslation();

    return (
        <LoadingMessageWrapper data-testid="loading-message-wrapper">
            <BackgroundImage
                src={BackgroundImg}
                data-testid="loading-message-bg"
                fade
            />
            <SplashContent data-testid="loading-message-content">
                <BaseBox bottom={6}>
                    <Spinner id="loader-icon" />
                </BaseBox>
                <Message data-testid="permission-allow-msg">
                    <BaseFont as="span" size={300}>
                        {splashScreenMessage}
                    </BaseFont>
                </Message>
                <Link
                    onClick={() => {
                        toggleSplashScreenVisibility(false);
                    }}
                    title={t('loadingMessageCancel')}
                    aria-label={t('loadingMessageCancel')}
                >
                    <BaseFont as="span" size={300}>
                        {t('loadingMessageCancel')}
                    </BaseFont>
                </Link>
            </SplashContent>
        </LoadingMessageWrapper>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        splashScreenMessage: state.splashScreen.message,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSplashScreenVisibility: (show: boolean) =>
        dispatch(toggleSplashScreenVisibility(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingMessage);
