import { AtmType } from '../shared/constants/enum';
import { AtmElement } from '../types/models';
import { getResponsiveType, ResponsiveType } from './responsive-utils';
import { getAtmType } from './utilities';

export type MarkerIconVariant = 'active' | 'recommended';

const DefaultUrls = {
    [AtmType.VISA]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-visa.png`,
    [AtmType.ING]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-ing.png`,
    [AtmType.PARTNER]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-cash-partner.png`,
    [AtmType.DEPOSIT]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-deposit.png`,
    [AtmType.CASHBACK]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-cashback.png`,
    [AtmType.VISA_CASHBACK]: `${process.env.PUBLIC_URL}/img/markers-png/lg/atm-visa-cashback.png`,
};

const MarkerIconSizes = {
    [ResponsiveType.PHONE]: [34, 40],
    [ResponsiveType.TABLET_SMALL]: [34, 40],
    //
    [ResponsiveType.TABLET_LARGE]: [38, 45],
    //
    [ResponsiveType.DESKTOP_SMALL]: [46, 55],
    [ResponsiveType.DESKTOP_LARGE]: [46, 55],
};

const DotIconSizes = {
    [ResponsiveType.PHONE]: [22, 22],
    [ResponsiveType.TABLET_SMALL]: [22, 22],
    //
    [ResponsiveType.TABLET_LARGE]: [22, 22],
    //
    [ResponsiveType.DESKTOP_SMALL]: [22, 22],
    [ResponsiveType.DESKTOP_LARGE]: [22, 22],
};

type IconUrlOptions = {
    retina?: boolean;
    responsive?: boolean;
    variant?: MarkerIconVariant;
};

function applyVariant(iconUrl: string, variant: MarkerIconVariant) {
    return iconUrl.replace('.png', `-${variant}.png`);
}

function applyRetina(iconUrl: string) {
    if (window.devicePixelRatio >= 3) {
        return iconUrl
            .replace('/sm/', `/sm-3x/`)
            .replace('/md/', `/md-3x/`)
            .replace('/lg/', `/lg-3x/`);
    }
    if (window.devicePixelRatio >= 2) {
        return iconUrl
            .replace('/sm/', `/sm-2x/`)
            .replace('/md/', `/md-2x/`)
            .replace('/lg/', `/lg-2x/`);
    }
    return iconUrl;
}

function applyResponsive(iconUrl: string) {
    switch (getResponsiveType()) {
        case ResponsiveType.PHONE:
        case ResponsiveType.TABLET_SMALL:
            return iconUrl.replace('/lg/', '/sm/');

        case ResponsiveType.TABLET_LARGE:
            return iconUrl.replace('/lg/', '/md/');

        case ResponsiveType.DESKTOP_SMALL:
        case ResponsiveType.DESKTOP_LARGE:
        default:
            return iconUrl;
    }
}

function applyOptions(
    url: string,
    { retina = true, responsive = true, variant }: IconUrlOptions = {}
) {
    if (variant) {
        url = applyVariant(url, variant);
    }

    if (responsive) {
        url = applyResponsive(url);
    }

    if (retina) {
        url = applyRetina(url);
    }

    return url;
}

export function getIconUrlFromType(type: AtmType, options?: IconUrlOptions) {
    const baseUrl = DefaultUrls[type] as string;
    const finalUrl = applyOptions(baseUrl, options);
    return finalUrl;
}

export function getIconUrlFromAtm(atm: AtmElement, options?: IconUrlOptions) {
    const type = getAtmType(atm);
    const baseUrl = DefaultUrls[type] as string;
    const finalUrl = applyOptions(baseUrl, options);
    return finalUrl;
}

export function getIconUrlFromUrl(url: string, options?: IconUrlOptions) {
    const finalUrl = applyOptions(url, options);
    return finalUrl;
}

export function getIconLabelFromAtm(atm: AtmElement) {
    const type = getAtmType(atm);
    const firstUpperLetter = type.charAt(0);
    const lowerCase = type.toLowerCase().slice(1);
    const resultLabel = firstUpperLetter.concat(lowerCase);
    return resultLabel;
}

export function createYellowmapIcon({
    atm,
    variant,
}: {
    atm: AtmElement;
    variant?: MarkerIconVariant;
}) {
    const atmType = getAtmType(atm);
    const iconUrl = getIconUrlFromType(atmType, { variant });
    const iconRetinaUrl = getIconUrlFromType(atmType, {
        variant,
        retina: true,
    });
    const iconSize = MarkerIconSizes[getResponsiveType()];

    const icon = new window.ym.modules.provider.Icon({
        iconUrl,
        iconRetinaUrl,
        iconSize,
    });

    return icon;
}

export function getUserLocationIcon() {
    const url = `${process.env.PUBLIC_URL}/img/markers-png/lg/location.png`;
    const iconUrl = getIconUrlFromUrl(url);
    const iconRetinaUrl = getIconUrlFromUrl(url, { retina: true });
    const iconSize = DotIconSizes[getResponsiveType()];

    const icon = new window.ym.modules.provider.Icon({
        iconUrl,
        iconRetinaUrl,
        iconSize,
    });

    return icon;
}
