import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { BaseBox, BaseFont } from '../../shared/constants/theme';
import { useTranslation } from 'react-i18next';
import * as SC from './MapAtmSearch.styled';
import { setMapMode } from '../../store/actions/common';
import { SMARTPHONE_UPPER_BOUND } from '../../shared/constants/default';
import { setMobileView } from '../../store/actions/common';
import { MobileView } from '../../shared/constants/enum';
import { MapMode } from '../../types/models';

const NavigateToMapView = (props: any) => {
    const { t } = useTranslation();
    const { setMapMode, setMobileView } = props;

    const openMapView = () => {
        if (setMapMode) {
            if (window.innerWidth <= SMARTPHONE_UPPER_BOUND) {
                setMobileView(MobileView.MAP);
            }
            setMapMode(MapMode.Maps);
        }
    };
    return (
        <BaseBox as="div" top={2} bottom={6}>
            <BaseFont as="span" size={300}>
                <SC.MapNavigationLink
                    onClick={openMapView}
                    title={t('activateMapView')}
                    aria-label={t('activateMapView')}
                >
                    {t('activateMapView')}
                </SC.MapNavigationLink>
            </BaseFont>
        </BaseBox>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setMapMode: (mode: number) => dispatch(setMapMode(mode)),
    setMobileView: (value: MobileView) => dispatch(setMobileView(value)),
});

export default connect(null, mapDispatchToProps)(NavigateToMapView);
