import styled from 'styled-components';
import { SquareIconButton } from '../../components/Button/SquareIconButton.styled';
import { DetailViewWrapper } from '../../components/Atm/Styles';
import { Image } from '../../components/Image';
import {
    smartphoneUpperBound,
    tabletLLowerBound,
    tabletLUpperBound,
    tabletSLowerBound,
    tabletSUpperBound,
} from '../../shared/constants/theme';

export const MainContainer = styled.div`
    margin: auto;
    display: flex;

    .leaflet-top.leaflet-left {
        position: static;
    }
    .leaflet-control-zoom {
        position: absolute;
        bottom: 30px;
        right: 10px;
        @media only screen and (max-width: 360px) {
            bottom: 70px;
        }
    }

    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: block;
    }
    height: 100%;
`;
export const MapContainer = styled.div`
    height: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 1;
    #map {
        height: 100%;
        background: #e5e3df; // yellowmap gray
    }
`;

export const NoMapDesktopContainer = styled.div`
    background: white;
    height: 100%;
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: none;
    }
    display: block;
`;

export const LeftPanelContainer = styled.div`
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        position: relative;
    }
`;

export const NoMapMobileContainer = styled.div`
    display: none;
    padding: 40px 12px 12px 12px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: white;
    overflow: auto;
    box-sizing: border-box;
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: block;
    }
`;

export const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: none;
    }
    animation: ${(props) =>
        props.theme.enableSearchBarFade ? 'fadein 1s' : 'none'};
    padding: 33px 22px 33px 22px;
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export const SearchBar = styled.div`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.theme.desktop1D * 80 + 'px'};
    height: 100%;
    ${DetailViewWrapper} {
        padding: 22px;
        box-sizing: border-box;
    }
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        width: ${(props) => props.theme.smartphone1D * 80 + 'px'};
        min-width: ${(props) => props.theme.smartphone1D * 80 + 'px'};
        display: none;
    }
    @media only screen and (min-width: ${tabletSLowerBound}) and (max-width: ${tabletSUpperBound}) {
        width: ${(props) => props.theme.tablet1D * 80 + 'px'};
        min-width: ${(props) => props.theme.tablet1D * 80 + 'px'};
    }
    @media only screen and (min-width: ${tabletLLowerBound}) and (max-width: ${tabletLUpperBound}) {
        width: ${(props) => props.theme.tablet1D * 80 + 'px'};
        min-width: ${(props) => props.theme.tablet1D * 80 + 'px'};
    }
`;

export const Views = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const MobileSearchBarWrapper = styled.div`
    display: none;
    height: 100%;
    position: relative;

    @media only screen and (max-width: ${smartphoneUpperBound}) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
    }
`;

export const SearchResultButtonWrapper = styled.div<{ hideButton: boolean }>`
    display: ${(props) => (props.hideButton ? 'none' : 'flex')};
    flex-direction: column;
    align-items: stretch;
    & > div {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    button {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 0.4em;
        }
    }
    @media only screen and (max-width: ${smartphoneUpperBound}) {
        justify-content: center;
    }
`;

export const ImgItem = styled(Image)`
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
`;

export const MapNavigationLink = styled.a`
    text-decoration: underline;
    color: ${(props) => props.theme.ingLinkColor};
    cursor: pointer;
`;

export const FullscreenButton = styled(SquareIconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);

    border-radius: 6px;

    &:active {
        outline: 0;
        opacity: 1;
        img {
            transform: scale(0.9);
        }
    }

    @media only screen and (max-width: ${smartphoneUpperBound}) {
        display: none;
    }
`;
