import React from 'react';
import { StyledButton } from './Button.styled';

type AppButtonProps = React.HTMLProps<HTMLButtonElement> & {
    ref?: any;
    as?: any;
    for?: any;
};
export default React.forwardRef(function AppButton(props: AppButtonProps, ref) {
    return <StyledButton type="button" {...props} ref={ref} />;
});
