import React, { ReactEventHandler } from 'react';

import BackArrow from '../../shared/img/back_arrow.svg';
import CloseIcon from '../../shared/img/close-icon.svg';

import styled from 'styled-components';
import {
    smartphoneUpperBound,
    tabletSLowerBound,
} from '../../shared/constants/theme';

const Container = styled.header`
    display: flex;

    padding: 12px 12px 0;
    box-sizing: border-box;

    @media screen and (min-width: ${tabletSLowerBound}) {
        padding: 0;
    }

    @media screen and (max-width: ${smartphoneUpperBound}) {
        padding: 12px;
        height: 48px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
        background: rgba(255, 255, 255, 0.9);
        & + * {
            padding-top: 48px !important;
            margin-top: 0 !important;
        }
    }

    button {
        appearance: none;
        background: transparent;
        outline: none;
        border: 0;
        padding: 0;

        cursor: pointer;
        height: 24px;
        width: 24px;

        &.btn-back {
            margin-right: auto;
        }

        &.btn-close {
            margin-left: auto;
        }

        img {
            height: 24px;
        }
    }
`;

type PanelHeaderProps = {
    onBackClick?: ReactEventHandler<HTMLButtonElement>;
    onCloseClick?: ReactEventHandler<HTMLButtonElement>;
    backButtonVisible?: boolean;
    closeButtonVisible?: boolean;
};
export const PanelHeader = ({
    onBackClick,
    onCloseClick,
    // flag default is true if callback is set. explicit flag value will always override it.
    backButtonVisible = !!onBackClick,
    closeButtonVisible = !!onCloseClick,
}: PanelHeaderProps) => {
    return (
        <Container>
            {backButtonVisible && (
                <button
                    className="btn-back"
                    onClick={onBackClick}
                    data-testid="btn-back"
                >
                    <img src={BackArrow} alt="Zurück" title="Zurück" />
                </button>
            )}
            {closeButtonVisible && (
                <button className="btn-close" onClick={onCloseClick}>
                    <img src={CloseIcon} title="Schließen" alt="Schließen" />
                </button>
            )}
        </Container>
    );
};
